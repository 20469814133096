body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@supports (padding: max(0px)) {
  :root {
    padding-left: max(5px, env(safe-area-inset-left));
    padding-right: max(5px, env(safe-area-inset-right));
    padding-top: max(12px, env(safe-area-inset-top));
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
  .bottomNavBar,
  .menuModalBottomButton {
    margin-bottom: max(12px, env(safe-area-inset-bottom)) !important;
  }
}
:root {
  background-color: #f7f7f7;
}
body {
  margin: 0px;
}
a {
  color: inherit;
  text-decoration: none;
}
.MuiBox-root {
  outline: 0;
}
.MuiDrawer-root a.selected-dark .MuiListItemButton-root {
  background-color: #a27b5c;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.app-table-row:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
main {
  overflow: auto;
}
.backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: start;
  justify-content: start;
  overflow-y: hidden;
}
.modal {
  /* height: min(50%, 300px); */
  overflow: scroll;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  left: 0;
  flex-direction: column;
  right: 0;
  height: 100%;
}